import i18n from '@/lang/i18n.js'
import { HALF_YEAR, HALF_YEAR_ICP, QUARTER_YEAR_ICP, QUARTER_YEAR } from '@/constants/pcaf';

const getTitle = (tab) => {
  let title = i18n.t("dashboard_main.label_year_on_year_ratio");
  switch (tab) {
    case 0:
      title = i18n.t("dashboard_main.label_year_on_year_ratio");
      break;
    case 1:
      title = i18n.t("dashboard_main.label_first_half_ratio");
      break;
    case 2:
      title = i18n.t("dashboard_main.label_compared_to_the_previous_quarter");
      break;
    case 3:
      title = i18n.t("dashboard_main.label_month_to_month_basis");
      break;
    default:
      break;
  }
  return title;
};
const getUnit = (type = 1, currency = 1, unit = 't-CO2') => {
  if ([1, 3].includes(type)) {
    return unit;
  } else if (type === 2) {
    let text = '';
    switch (currency) {
      case 1:
        text = i18n.t("dashboard_main.label_one_million_yen");
        break;
      case 2:
        text = 'k USD';
        break;
      default:
        text = 'k EUR';
        break;
    }
    return text;
  }
}
const getTitleCompare = (tab) => {
  let titleCompare = i18n.t("dashboard_main.label_year_on_year_comparison");
  switch (tab) {
    case 1:
    case 2:
      titleCompare = i18n.t("dashboard_main.label_year_on_year_comparison");
      break;
    case 3:
      titleCompare = i18n.t("dashboard_main.label_compared_to_the_same_month_last_year");
      break;
    default:
      break;
  }
  return titleCompare;
};
const getTitleCompareFullText = (tab) => {
  let titleCompare = i18n.t("dashboard_main.label_year_on_year_comparison_fulltext");
  switch (tab) {
    case 1:
    case 2:
      titleCompare = i18n.t("dashboard_main.label_year_on_year_comparison_fulltext");
      break;
    case 3:
      titleCompare = i18n.t("dashboard_main.label_compared_to_the_same_month_last_year_fulltext");
      break;
    default:
      break;
  }
  return titleCompare;
};
const getNextPrevTitle = (tab) => {
  let titleNextPrev = {
    next: i18n.t("dashboard_main.label_next_year"),
    prev: i18n.t("dashboard_main.label_previous_year_comparison"),
  };
  switch (tab) {
    case 0:
      titleNextPrev = {
        next: i18n.t("dashboard_main.label_next_year"),
        prev: i18n.t("dashboard_main.label_previous_year_comparison"),
      };
      break;
    case 1:
      titleNextPrev = {
        next: i18n.t("dashboard_main.label_second_half_1"),
        prev: i18n.t("dashboard_main.label_first_half"),
      };
      break;
    case 2:
      titleNextPrev = {
        next: i18n.t("dashboard_main.label_next_quarter"),
        prev: i18n.t("dashboard_main.label_last_quarter"),
      };
      break;
    case 3:
      titleNextPrev = {
        next: i18n.t("dashboard_main.label_next_year"),
        prev: i18n.t("dashboard_main.label_previous_year_comparison"),
      };
      break;
    default:
      break;
  }
  return titleNextPrev;
};
const getDateCurrent = (tab = 0, startMonth = null, isICp = false) => {
  let durationsTimeCurrent = {};
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  let currentMonth = currentDate.getMonth() + 1; // Adding 1 to get actual month number (1 to 12)
  if(startMonth) {
    if (startMonth > currentMonth) {
      currentYear = currentYear - 1;
      currentMonth = 12 - startMonth + currentMonth + 1;
    }
    else {
      currentMonth = currentMonth - startMonth + 1;
    }
  }
  durationsTimeCurrent.year = currentYear;
  if (tab === 1) {
    const idHalfYearCurrent = currentMonth <= 6 ? 1 : 2;
    const halfYearConst = isICp ? HALF_YEAR_ICP : HALF_YEAR;
    const halfYear = halfYearConst.find((item) => item.id === idHalfYearCurrent);
    durationsTimeCurrent['halfYear'] = halfYear;
  } else if (tab === 2) {
    const idQuarterYearCurrent = Math.ceil(currentMonth / 3);
    const quarterYearConst = isICp ? QUARTER_YEAR_ICP : QUARTER_YEAR;
    const quarterYear = quarterYearConst.find((item) => item.id === idQuarterYearCurrent);
    durationsTimeCurrent['quarterYear'] = quarterYear;
  } else if (tab === 3) { // tab year is selected value currently
    durationsTimeCurrent['month'] = currentDate.getMonth() + 1;
    durationsTimeCurrent.year = currentDate.getFullYear();
  }

  return durationsTimeCurrent;
}

function capitalizeFirstLetterInSentence(sentence) {
  const words = sentence?.trim().split(" ");
  const capitalizedWords = words.map((word, index) => {
    if(index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word
  });
  return capitalizedWords.join(" ");
}

const chartTitleMonthYear = (tab = 0, year, order = 0, month, type = '') => {
  let title = '';
  switch (tab) {
    case 0:
      title = i18n.t("dashboard_main.label_title_column_chart_details_emissions_tab_year", {year, type})
      break;
    case 1:
      if (order === 1) {
        title = i18n.t("dashboard_main.label_title_column_chart_details_emissions_first_half_year", {year: year, type})
      }
      else title = i18n.t("dashboard_main.label_title_column_chart_details_emissions_end_half_year", {year: year, type})
      break;
    case 2:
      const quarterOrderValue = QUARTER_YEAR.find((text) => text.id === order)?.id;
      title = i18n.t("dashboard_main.label_title_column_chart_details_emissions_quarter_year", {year, order: quarterOrderValue, type})
      break;
    default:
      title = i18n.t("dashboard_main.label_title_column_chart_details_emissions_tab_month", {year, month, type})
      break;
  }
  return  i18n.locale === 'vi' ? capitalizeFirstLetterInSentence(title) : title; //capitalize first letter if lang is vietnamese
};
const getRatioValue = (tab = 0, data, isGetCompare = false) => {
  let ratioValue = '';
  switch (tab) {
    case 0:
      ratioValue = isGetCompare ?  data?.rate_last__year : data?.rate_last__year_same;
      break;
    case 1:
      ratioValue = isGetCompare ?  data?.rate_last_half_year : data?.rate_last_half_year_same;
      break;
    case 2:
      ratioValue = isGetCompare ? data?.rate_last_quarter_year  : data?.rate_last_quarter_year_same ;
      break;
    case 3:
      ratioValue = isGetCompare ? data?.rate_last_month : data?.rate_last_month_same;
      break;
    default:
      break;
  }
  return ratioValue;
};
const getTotalEmissionValue = (tab = 0, data) => {
  let totalEmissionValue = '';
  switch (tab) {
    case 0:
      totalEmissionValue = data?.total_full_year;
      break;
    case 1:
      totalEmissionValue = data?.total_half_year;
      break;
    case 2:
      totalEmissionValue = data?.total_quarter_year;
      break;
    case 3:
      totalEmissionValue = data?.total_month;
      break;
    default:
      break;
  }
  return totalEmissionValue;
};
const getRatioByCategory = (tab = 0, data) => {
  let totalEmissionValue = '';
  switch (tab) {
    case 0:
      totalEmissionValue = data?.rate_full_year;
      break;
    case 1:
      totalEmissionValue = data?.rate_half_year;
      break;
    case 2:
      totalEmissionValue = data?.rate_quarter_year;
      break;
    case 3:
      totalEmissionValue = data?.rate_month;
      break;
    default:
      break;
  }
  return totalEmissionValue;
};
const chartTitleMonthYearInvestMent = (tab = 0, year, order = 0, month) => {
  let title = '';
  switch (tab) {
    case 0:
      title = i18n.t("dashboard_main.label_fy_yyyy_investment_and_loan_amount", {YYYY: year})
      break;
    case 1:
      if (order === 1) {
        title = i18n.t("dashboard_main.label_first_half_of_fiscal_year_yyyy_investment_and_loan_amount", {YYYY: year})
      }
      else title = i18n.t("dashboard_main.label_second_half_of_fiscal_year_yyyy_investment_and_loan_amount", {YYYY: year})
      break;
    case 2:
      title = i18n.t("dashboard_main.label_fy_yyyy_xq_investment_amount", {YYYY: year, X: order})
      break;
    default:
      title = i18n.t("dashboard_main.label_fy_yyyy_month_mm_investment_and_loan_amount", {YYYY: year, MM: month})
      break;
  }
  return title;
}
const chartTitleMonthYearGHG = (tab = 0, year, order = 0, month) => {
  let title = '';
  switch (tab) {
    case 0:
      title = i18n.t("dashboard_main.label_fy_yyyy_ghg_emissions", {YYYY: year})
      break;
    case 1:
      if (order === 1) {
        title = i18n.t("dashboard_main.label_first_half_of_fiscal_year_yyyy_ghg_emissions", {YYYY: year})
      }
      else title = i18n.t("dashboard_main.label_second_half_of_fiscal_year_yyyy_ghg_emissions", {YYYY: year})
      break;
    case 2:
      title = i18n.t("dashboard_main.label_fy_yyyy_xq_ghg_emissions", {YYYY: year, X: order})
      break;
    default:
      title = i18n.t("dashboard_main.label_fy_yyyy_month_mm_ghg_emissions", {YYYY: year, MM: month})
      break;
  }
  return title;
}

const chartTitleMonthYearfinanced = (tab = 0, year, order = 0, month) => {
  let title = '';
  switch (tab) {
    case 0:
      title = i18n.t("dashboard_main.label_fy_yyyy_financed_emissions", {YYYY: year})
      break;
    case 1:
      if (order === 1) {
        title = i18n.t("dashboard_main.label_first_half_of_fiscal_year_yyyy_financed_emissions", {YYYY: year})
      }
      else title = i18n.t("dashboard_main.label_second_half_of_fiscal_year_yyyy_financed_emissions", {YYYY: year})
      break;
    case 2:
      title = i18n.t("dashboard_main.label_fy_yyyy_xq_financed_emissions", {YYYY: year, X: order})
      break;
    default:
      title = i18n.t("dashboard_main.label_fy_yyyy_month_mm_financed_emissions", {YYYY: year, MM: month})
      break;
  }
  return title;
}
export {
  getTitle,
  getUnit,
  getTitleCompare,
  getTitleCompareFullText,
  getNextPrevTitle,
  getDateCurrent,
  chartTitleMonthYear,
  getRatioValue,
  getTotalEmissionValue,
  getRatioByCategory,
  chartTitleMonthYearInvestMent,
  chartTitleMonthYearGHG,
  chartTitleMonthYearfinanced
};
